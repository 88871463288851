import React from 'react'
 
 
const LOGO = () => {
  return (
    <div className='logo'>
 
     <a href="https://lekeo.pl" ><img src="logo.png" alt = "lekeo.pl" /></a>

</div>


    
  )
}
export default LOGO

 