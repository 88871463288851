const SETTINGS_pub = {
	priceSearch: "/ep/pricesearch?",
	search_url: "/ep/lekName?v=",
	shipment: "/ep/shipment?",
	multipleShipment: "/ep/multipleShipment",
	host: "https://lekeo.pl",
};
const SETTINGS_priv = {
	priceSearch: "/pricesearch?",
	search_url: "/lekName?v=",
	shipment: "/shipment?",
	multipleShipment: "/multipleShipment",
	host: "http://localhost",
	port: 3002,
};

const SETTINGS = SETTINGS_pub;

export default SETTINGS;
