import React from "react";

const Drugstore_product = (props) => {
	return (
		<div className="panel-block price_block">
			<div className="field is-grouped is-grouped-multiline     ">
				<div className="control price-tag-block">
					<div className=" field is-grouped tags is-grouped-multiline   control  has-addons">
						<span className="tag  is-info is-small  price ">
							{" "}
							{props.price}
						</span>
						<span className="tag is-info  is-light is-small price">
							{" "}
							x {props.quantity}={" "}
						</span>
						<span className="tag   is-warning  is-small price">
							{(props.quantity * props.price).toFixed(2)} zł
						</span>
					</div>
				</div>

				{props.is_lek === 1 && (
					<div>
						<div className="control  ">
							<strong className=" tag lek is-link tooltip is-small">
							<span className="tooltiptext">Produkt leczniczy, ma wpływ na koszt wysyłki</span>
								LEK
							</strong>
						</div>
					</div>
				)}

				<div className="control">
					<div>
						<span className="prod_link">
							<a href={props.ext_url_short}>
								{props.description}
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Drugstore_product;
