import React, { useState, useEffect, useCallback } from "react";
import Drugstore_item from "./Drugstore_item";
import useHttp from "../hooks/useHttp";
import SETTINGS from "../settings/settings";
import loading from "../assets/loading.gif";

const isValidShipmentCost = (number) => {
	return isFinite(number) && number !== null;
};

const Drugstore_list = (props) => {
	const [isLoading, setIsLoading] = useState(false);

	const [shopsWithPrices, setShopsWithPrices] = useState([]);
	const { getDataOfProduct, isDataLoaded, currentProductData, getMultipleShipPrices } = useHttp();

	/**
	 * Liczenie ceny dostawy dla sklepu
	 */
	const calculateShipmentCost = useCallback(
		async (shopsWithoutShipment) => {
			const shopsToGetDataAbout = [];
			const shopsWithPrices = [];
			shopsWithoutShipment.forEach((shop) => {
				if (shop.isDrug) {
					if (shop.shopData[0].kurier_cost !== null) {
						if (
							shop.shopData[0].kurier_free_margin <= shop.totalPrice &&
							shop.shopData[0].kurier_free_margin !== null
						)
							shopsWithPrices.push({
								...shop,
								shop_id: shop.shopData[0].shop_id,
								shipmentCost: 0,
							});
						else
							shopsWithPrices.push({
								...shop,
								shop_id: shop.shopData[0].shop_id,
								shipmentCost: parseFloat(shop.shopData[0].kurier_cost),
							});
					} else {
						shopsToGetDataAbout.push({
							...shop,
							totalPrice: shop.totalPrice,
							shop_id: shop.shopData[0].shop_id,
							isDrug: shop.isDrug,
						});
					}
				} else {
					if (shop.shopData[0].ship_cost !== null) {
						if (
							shop.shopData[0].ship_free_margin <= shop.totalPrice &&
							shop.shopData[0].ship_free_margin !== null
						)
							shopsWithPrices.push({
								...shop,
								shop_id: shop.shopData[0].shop_id,
								shipmentCost: 0,
							});
						else
							shopsWithPrices.push({
								...shop,
								shop_id: shop.shopData[0].shop_id,
								shipmentCost: parseFloat(shop.shopData[0].ship_cost),
							});
					} else {
						shopsToGetDataAbout.push({
							...shop,
							totalPrice: shop.totalPrice,
							shop_id: shop.shopData[0].shop_id,
							isDrug: shop.isDrug,
						});
					}
				}
			});

			const shopsWithFetchedPrices = await getMultipleShipPrices(shopsToGetDataAbout);
			console.log("shopsWithFetchedPrices",shopsWithFetchedPrices)

			const shipmentCosts = shopsWithFetchedPrices["shipment_cost"];
			for (const shop of shipmentCosts) {
				
				const index = shopsToGetDataAbout.findIndex(
					(element) => element.shop_id === shop.shop_id
				);
				//if (index === 0)   <------ ??????????
					shopsToGetDataAbout[index] = {
						...shopsToGetDataAbout[index],
						shipmentCost: parseFloat(shop.shipment_cost),
					};
			}
			const mergedShops = shopsWithPrices.concat(shopsToGetDataAbout);
			 
			mergedShops.forEach((shop) => {
				if (shop.shipmentCost !== null && !isNaN(shop.shipmentCost))
					shop.totalPrice += shop.shipmentCost;
			});
			return mergedShops;
		},
		[getMultipleShipPrices]
	);

	/**
	 * Funkcja Licząca cenę całkowitą sklepu
	 */
	const getTotalPrice = useCallback(
		async (shops) => {
			let totalPrice = 0;
			let quantity = 0;
			let shopsWithoutShipment = [];
			let shopsWithTotalPrice = [];

			/**
			 *  Zmienna przechowująca informację, czy w zamówieniu jest lek
			 */
			let isDrug = false;

			for (const shop of shops) {
				totalPrice = 0;
				isDrug = false;

				for (const medicine of shop.shopData) {
					quantity = 0;
					if (props.tagData.length > 0) {
						const selectedMedicine = props.tagData.find((tag) => {
							return tag.guid === medicine.guid;
						});
						quantity = selectedMedicine.quantity;

						totalPrice += quantity * parseFloat(medicine.value);

						if (medicine.is_lek) isDrug = true;
					}
				}
				shopsWithoutShipment.push({
					isDrug,
					totalPrice,
					shopData: shop.shopData,
					shipmentCost: null,
				});
			}
			if (props.isShipmentIncluded) {
				shopsWithTotalPrice = await calculateShipmentCost(shopsWithoutShipment);
			} else shopsWithTotalPrice = shopsWithoutShipment;
			return shopsWithTotalPrice;
		},
		[calculateShipmentCost, props.isShipmentIncluded, props.tagData]
	);

	/**
	 * Funkcja licząca ceny dla wszystkich sklepów
	 */
	const calculatePrices = useCallback(async () => {
		let uniqueShopIdList = [];
		setIsLoading(true);
		// shop list
		const shopList = currentProductData.map((val) => {
			return val.shop_id;
		});

		uniqueShopIdList = shopList.filter((item, i, ar) => ar.indexOf(item) === i);

		let tempProductsOfAllShops = uniqueShopIdList.map((currentShopId) => {
			const shopData = currentProductData.filter((filter_shop) => {
				return filter_shop.shop_id === currentShopId;
			});
			return {
				shopData,
				id: shopData[0].shop_id,
				totalPrice: 0,
				shipmentCost: 0,
			};
		});
		/**
		 * wybranie tylko tych sklepów, które posiadają wszystkie produkty
		 */
		tempProductsOfAllShops = tempProductsOfAllShops.filter((shop) => {
			if (props.tagData.length === 0) return false;
			else return shop.shopData.length === props.tagData.length;
		});
		const totalPriceData = await getTotalPrice(tempProductsOfAllShops);

	//	console.log()
		totalPriceData.sort(
			(a, b) => {
		if(props.isShipmentIncluded === true) {
				if (!isValidShipmentCost(a.shipmentCost) && !isValidShipmentCost(b.shipmentCost)) {
					return 0;
				}
				if (!isValidShipmentCost(a.shipmentCost)) {
					return 1;
				}
				if (!isValidShipmentCost(b.shipmentCost)) {
					return -1; 
				} 
		}
			return a.totalPrice > b.totalPrice ? 1 : -1;
		}
		);

		setIsLoading(false);
		setShopsWithPrices(totalPriceData);
	}, [currentProductData, getTotalPrice, props.tagData]);

	const handleShipmentIncluded = () => {
		props.onIsShipmentIncludedChange();
	};

	useEffect(() => {
		if (props.tagData.length > 0) {
			let link =
				SETTINGS.host + (SETTINGS.port ? `:${SETTINGS.port}` : "") + SETTINGS.priceSearch;
			for (const item of props.tagData) {
				link += "v=" + item.guid + "&";
			}
			link = link.slice(0, -1);
			getDataOfProduct(link);
		}
	}, [getDataOfProduct, props.tagData]);

	useEffect(() => {
		if (isDataLoaded) calculatePrices();
	}, [calculatePrices, isDataLoaded, props.isShipmentIncluded]);

	return (
		<div className="result_contener">
			{shopsWithPrices.length > 0 && !isLoading && (
				<div className="header_drug_list">
					<h2 className="title is-5">Apteki w których są wszystkie szukane pozycje:</h2>
					<div className="header_shipment_input">
						<label className="checkbox" htmlFor="shipment_checkbox">
							Dolicz wysyłkę
						</label>
						<input
							id="shipment_checkbox"
							type="checkbox"
							checked={props.isShipmentIncluded}
							value={props.isShipmentIncluded}
							onChange={handleShipmentIncluded}
						/>
					</div>
				</div>
			)}
			{!isLoading &&
				shopsWithPrices.map((item, id) => {
					//console.log("i::",item.shop_id,item)
					return (
						<Drugstore_item
							key={item.id}
							id={item.id}
							shopData={item.shopData}
							priceSum={item.totalPrice}
							shipmentCost={item.shipmentCost}
							tagData={props.tagData}
							className={"drugstore-item"}
							timeout={id * 130}
							isShipmentIncluded={props.isShipmentIncluded}
						/>
					);
				})}
			{props.tagData.length > 0 && isLoading && (
				<img src={loading} alt="Nie wczytano obrazka" className="load" />
			)}
			{props.tagData.length > 0 && !isLoading && shopsWithPrices.length === 0 && (
				<h2>Nie znaleziono Apteki z takimi produktami</h2>
			)}
		</div>
	);
};

export default Drugstore_list;
