import React, { useState } from "react";

import Drugstore_product from "./Drugstore_product";
import "./Drugstore_item.css";
import { useEffect } from "react";

const Drugstore_item = (props) => {
	const [classToAdd, setClassToAdd] = useState();

	let isDrug = false;
	props.shopData.forEach((element) => {
		if (element.is_lek) isDrug = true;
	});

	const free_margin = isDrug
		? props.shopData[0].kurier_free_margin
		: props.shopData[0].ship_free_margin;

	useEffect(() => {
		setClassToAdd(props.className);
		setTimeout(() => {
			setClassToAdd(props.className + "-appear");
		}, props.timeout);
	}, [props.isShipmentIncluded]);

	let priceTooltipText = false;
	if (props.isShipmentIncluded === false)
		priceTooltipText = "Suma wszystkich produktów bez kosztów wysyłki";
	else priceTooltipText = "Suma wszystkich produktów z uwzględnieniem kosztów wysyłki";

	let isFreeShipMarginStyle = "";

	let style_withiout_shippment = " tag tooltip is-link is-small"
	if(isNaN(props.shipmentCost) || props.shipmentCost === null) 
	style_withiout_shippment = " tag tooltip is-danger is-small"

	const shipmentFactor =
		isNaN(props.shipmentCost) || props.shipmentCost === null
			? 0
			: props.shipmentCost.toFixed(2);

	if (props.priceSum !== null && props.priceSum.toFixed(2) - shipmentFactor >= free_margin)
		isFreeShipMarginStyle = "is-success";
	else isFreeShipMarginStyle = "is-warning";

	return (
		<div className={`message is-success drugstore-item ${classToAdd}`}>
			<div>
				<div className="message-body accordion">
					<a href={props.shopData[0].shop_url}>{props.shopData[0].shop_name}</a>

					<strong className="price tag tooltip is-info is-medium">
						<span className="tooltiptext ">{priceTooltipText}</span>
						{props.priceSum === null ? "Brak danych" : props.priceSum.toFixed(2)} zł
					</strong>
				</div>
				<div>
					{props.shopData.map((item, key) => {
						let quantity = 0;
						if (
							props.tagData.length > 0 &&
							props.tagData.length === props.shopData.length
						) {
							let selectedTags = props.tagData.filter((tag) => {
								return tag.guid === item.guid;
							});
							if (selectedTags.length === 1) quantity = selectedTags[0].quantity;
						}
						return (
							<Drugstore_product
								key={key}
								quantity={quantity}
								description={item.description}
								price={item.value}
								is_lek={item.is_lek}
								ext_url_short={item.ext_url_short}
							/>
						);
					})
					
					}
 


					{props.isShipmentIncluded && ( // ukrywanie informacji o wysyłce gdy nie przeliczamy cen wysyłki
						<div className=" field is-grouped tags is-grouped-multiline panel-block  control  has-addons fsize">
							<strong className="tag tooltip is-link is-small" >
								<span className="tooltiptext">
									Koszty Dostawy, koszt może być zależny od zawartości koszyka
								</span>
								{isNaN(props.shipmentCost) || props.shipmentCost === null
									? "Brak danych o koszcie dostawy"
									: `Dostawa od ${props.shipmentCost.toFixed(2)}`}
							</strong>
							
							{free_margin && (
								<strong
									className={`tag  tooltip is-small ${isFreeShipMarginStyle} `}
								>
									<span className="tooltiptext">
										Kwota od której jest darmowa wysyłka
									</span>
									darmowa od {free_margin}
								</strong>
							)}

							{props.shopData[0].ship_url && (
								<>
									<strong className="  tag is-link tooltip is-small  ">
										<span className="tooltiptext">Szczegóły dostawy</span>
										<a href={props.shopData[0].ship_url}> Szczegóły » </a>
									</strong>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Drugstore_item;
