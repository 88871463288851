import React from 'react'

const Footer = () => {
  return (
    <footer >
  <div className=" has-text-centered">
    <p>

       Uwagi, współpraca, zgłoszenia nieaktualnych cen:
        <a href="mailto:kontakt@lekeo.pl"> kontakt@lekeo.pl </a>
    </p>
  </div>
</footer>
  )
}

export default Footer