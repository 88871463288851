import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { fadeOut } from "react-animations";

const animation = keyframes`${fadeOut}`;

const FadeOutWrapper = styled("div")`
	animation=0.7s ${animation}
`;

const Autocomplete_selected = (props) => {
	//const [search, setSearch] = useState(1);
	const [fadingTagGUID, setFadingTagGUID] = useState("");

	const handlerDelete = (guid) => {
		setFadingTagGUID(guid);
		setTimeout(() => {
			props.onRemoveTag(guid);
			setFadingTagGUID("");
		}, 500);
	};

	const handlerQuantity = (e, guid) => {
		props.onChangeQuantity(guid, e.target.value);
	};

	return (
		<div className="tags">
			{props.tagData.map((item) => {
				return (
					<div key={item.guid} className ="margin_right_5">
						{fadingTagGUID === item.guid ? (
							<FadeOutWrapper>
								<span className="tag is-info is-large" >
									{item.name}

									<div className="select is-small">
										<select
											value={item.quantity}
											onChange={(e) => {
												handlerQuantity(e, item.guid);
											}}
										>
											<option value="1">x 1</option>
											<option value="2">x 2</option>
											<option value="3">x 3</option>
											<option value="4">x 4</option>
											<option value="5">x 5</option>
											<option value="6">x 6</option>
											<option value="7">x 7</option>
											<option value="8">x 8</option>
											<option value="9">x 9</option>
										</select>
									</div>

									<button
										className="delete"
										onClick={() => {
											handlerDelete(item.guid);
										}}
									></button>
								</span>
							</FadeOutWrapper>
						) : (
							<span key={item.guid} className="tag is-info is-large">
								{item.name}

								<div className="select is-small">
									<select
										value={item.quantity}
										onChange={(e) => {
											handlerQuantity(e, item.guid);
										}}
									>
										<option value="1">x 1</option>
										<option value="2">x 2</option>
										<option value="3">x 3</option>
										<option value="4">x 4</option>
										<option value="5">x 5</option>
										<option value="6">x 6</option>
										<option value="7">x 7</option>
										<option value="8">x 8</option>
										<option value="9">x 9</option>
									</select>
								</div>

								<button
									className="delete"
									onClick={() => {
										handlerDelete(item.guid);
									}}
								></button>
							</span>
						)}
					</div>
				);
			})}
			{props.tagData.length > 1 && (
				<span className="tag is-danger del is-large">
					<button className="delete" onClick={props.onRemoveAllTags}>
						X
					</button>
				</span>
			)}
		</div>
	);
};

export default Autocomplete_selected;
