import { useState, useCallback } from "react";
import SETTINGS from "../settings/settings";

const useHttp = () => {
	const [currentProductData, setCurrentProductData] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	/**
	 * Funkcja pobierająca dane leków ze wszystkich aptek
	 * @param url Adres endpointa do pobrania danych
	 */
	const getDataOfProduct = useCallback(async (url) => {
		const response = fetch(url)
			.then((res) => res.json())
			.then((data) => {
				setCurrentProductData(data.data);
				setIsDataLoaded(true);
				return "success";
			})
			.catch((error) => {
				return "failure";
			});
		return response;
	}, []);

	/**
	 * Funkcja pobierająca koszt wysyłki danego produktu
	 * @param {boolean} isDrug Czy dany produkt jest lekiem
	 */
	const getShipPrice = useCallback((shopId, price, isDrug) => {
		const url =
			SETTINGS.host +
			":" +
			(SETTINGS.port ? `:${SETTINGS.port}` : "") +
			SETTINGS.shipment +
			`shop_id=${shopId}&price=${price}&isDrug=${isDrug ? 1 : 0}`;
		const response = fetch(url)
			.then((res) => res.json())
			.then((data) => {
				return data.shipment_cost;
			})
			.catch((error) => {
				console.log(error);
			});
		return response;
	}, []);

	const getMultipleShipPrices = useCallback(async (shopsToGetDataAbout) => {
		const url =
			SETTINGS.host +
			":" +
			(SETTINGS.port ? `:${SETTINGS.port}` : "") +
			SETTINGS.multipleShipment;
		const response = fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(shopsToGetDataAbout),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log(data)
				return data;
			})
			.catch((error) => {
				console.log(error);
			});
		return response;
	}, []);

	return {
		getDataOfProduct,
		isDataLoaded,
		currentProductData,
		getShipPrice,
		getMultipleShipPrices,
	};
};

export default useHttp;
