import React, { useState, useEffect } from "react";
import Autocomplete_root from "./components/Autocomplete_root";
import Autocomplete_selected from "./components/Autocomplete_selected";
import Drugstore_list from "./components/Drugstore_list";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
	// [{guid,value,quantity},{},{},{}]
	const [tagData, setTagData] = useState([]);
	const [isShipmentIncluded, setIsShipmentIncluded] = useState(false);

	const onRemoveTag = (guid) => {
		const removedTag = tagData.filter((item) => {
			return item.guid != guid;
		});
		localStorage.setItem("tagData", JSON.stringify(removedTag));
		setTagData(removedTag);
	};

	const onRemoveAllTags = () => {
		localStorage.setItem("tagData", "[]");
		setTagData([]);
	};

	const onAddTag = (guid, value) => {
		const existingWithThatId = tagData.filter((item) => {
			return item.guid == guid;
		});
		if (existingWithThatId.length === 0) {
			setTagData((state) => {
				localStorage.setItem(
					"tagData",
					JSON.stringify([...state, { guid, name: value, quantity: 1 }])
				);
				return [...state, { guid, name: value, quantity: 1 }]; // {guid,value} => {guid:..., value:...}
			});
		}
	};

	const onIsShipmentIncludedChange = () => {
		setIsShipmentIncluded((prevState) => {
			localStorage.setItem("isShipmentIncluded", !prevState);
			return !prevState;
		});
	};

	const onChangeQuantity = (guid, quantity) => {
		setTagData((state) => {
			let temp = state.map((item) => {
				if (item.guid === guid) return { ...item, quantity: parseInt(quantity) };
				else return item;
			});
			localStorage.setItem("tagData", JSON.stringify(temp));
			return temp;
		});
	};

	useEffect(() => {
		const tagDataFromStorage = JSON.parse(localStorage.getItem("tagData"));
		const isShipmentIncludedFromStorage = JSON.parse(
			localStorage.getItem("isShipmentIncluded")
		);
		if (tagDataFromStorage) setTagData(tagDataFromStorage);
		if (isShipmentIncludedFromStorage !== undefined)
			setIsShipmentIncluded(isShipmentIncludedFromStorage);
	}, []);

	return (
		<>
			<Header />
			<Autocomplete_root onAddTag={onAddTag} />
			<Autocomplete_selected
				tagData={tagData}
				onRemoveTag={onRemoveTag}
				onChangeQuantity={onChangeQuantity}
				onRemoveAllTags={onRemoveAllTags}
			/>
			<Drugstore_list
				tagData={tagData}
				isShipmentIncluded={isShipmentIncluded}
				onIsShipmentIncludedChange={onIsShipmentIncludedChange}
			/>
			<Footer />
		</>
	);
};

export default App;
