import React, { useState, useEffect } from "react";
import classes from "./Autocomplete_root.module.css";
import SETTINGS from "../settings/settings";
import { DebounceInput } from "react-debounce-input";
//import drug_icon from "../assets/drug_icon.png";
/**
 * Żeby dostać propsy - przechwytujemy 1 parametr
 */
const Autocomplete_root = (props) => {
	const getAutocompleteData = (data) => {
		/**
		 * TODO: data.data
		 */
		//console.log("DATA:", data.data);
		setAutocompleteData(data.data);
	};

	const fetchData = (searchText) => {
		fetch(
			SETTINGS.host +
				(SETTINGS.port ? `:${SETTINGS.port}` : "") +
				SETTINGS.search_url +
				searchText
		)
			.then((response) => response.json())
			.then((data) => {
				getAutocompleteData(data);
			})
			.catch((error) => {
				console.log("Zapytanie do bazy o konkretne produkty zakończone niepowodzeniem!");
			});
	};

	useEffect(() => {
		fetchData("");
	}, []);

	const [search, setSearch] = useState("");
	const [isDropdownShown, setIsDropdownShown] = useState(false);
	const [autocompleteData, setAutocompleteData] = useState([]);
	const [searchPlaceholder, setSearchPlaceholder] = useState(true);

	let placeholder = "";
	if (searchPlaceholder) placeholder = "Podaj nazwę produktu jakiego szukasz"; // do use state
	else placeholder = "Podaj nazwę kolejnego produktu";

	const handleSearch = (event) => {
		setSearch(event.target.value);
		fetchData(event.target.value);
		if (event.target.value.length > 0) {
			setIsDropdownShown(true);
		} else {
			setIsDropdownShown(false);
		}
	};

	const handleClickResult = (guid, name) => {
		props.onAddTag(guid, name);
		setSearch("");
		setIsDropdownShown(false);
		setSearchPlaceholder(false);
	};

	return (
		<section role="search" className="panel is-success marg_top">
			<div className="control has-icons-left">
				<DebounceInput
					onChange={handleSearch}
					value={search}
					className="input  is-large autocomplete_input"
					type="text"
					debounceTimeout={300}
					placeholder={placeholder}
				/>
				<span className="icon fa-search is-left"></span>
				{isDropdownShown ? (
					<div className={classes["autocomplete-dropdown"]}>
						{autocompleteData.map((val, key) => {
							if (val.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
								return (
									<a
										onClick={() => {
											handleClickResult(val.guid, val.name);
										}}
										key={val.guid}
										className="panel-block is-active"
										style={{ cursor: "pointer" }}
									>
										{
											<span className="panel-icon is-normal">
												{val.is_lek === 1 && (
													<i className="drug_otc_ico"></i>
												)}
											</span>
										}

										<span className="autoComplete_name"> {val.name}</span>

										<span className="tag  forma  is-primary  is-small is-light price">
											{val.form}
										</span>
										<span className="tag producent  is-success  is-small is-light price">
											{val.producent}
										</span>
									</a>
								);
						})}
					</div>
				) : null}
				{/* isDropdownShown && <div className='autocomplete-dropdown'>123</div> */}
			</div>
		</section>
	);
};

export default Autocomplete_root;
